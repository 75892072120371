import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Mother's Day Heist",
  date: new Date(2024, 4, 19),
  img: "/img/mother.jpeg",
  video: "/videos/mother.mp4",
  tracks: [
    {
      title: "Field Of Butterflies",
      src: "/songs/2024-05-12/field_of_butterflies.mp3",
      duration: 547.656,
    },
    {
      title: "Some Kind Of Haze",
      src: "/songs/2024-05-12/some_kind_of_haze.mp3",
      duration: 577.152,
    },
    {
      title: "Playing Hard To Get",
      src: "/songs/2024-05-12/playing_hard_to_get.mp3",
      duration: 872.16,
    },
    {
      title: "Happy Go Lucky",
      src: "/songs/2024-05-12/happy_go_lucky.mp3",
      duration: 1135.92,
    },
    {
      title: "Say It Like That",
      src: "/songs/2024-05-12/say_it_like_that.mp3",
      duration: 684.384,
    },
    {
      title: "Synth Canadian Stand Off",
      src: "/songs/2024-05-12/06-1986-Synth-Canadian-Stand-off.mp3",
      duration: 860.544,
    },
    {
      title: "Midway",
      src: "/songs/2024-05-12/08-MidWay.mp3",
      duration: 741.12,
    },
    {
      title: "The Lil One",
      src: "/songs/2024-05-12/10-The-Lil-One.mp3",
      duration: 103.752,
    },
    {
      title: "The Big One",
      src: "/songs/2024-05-12/11-The-Big-one.mp3",
      duration: 1844.232,
    },
    {
      title: "San Martin Part 1",
      src: "/songs/2024-05-12/12-San-Martin-Part-1.mp3",
      duration: 705.48,
    },
    {
      title: "San Martin Part 2",
      src: "/songs/2024-05-12/12.5-San-Martin-Pt-2.mp3",
      duration: 303.576,
    },
    {
      title: "Humanity is a Dog",
      src: "/songs/2024-05-12/humanity_is_a_dog.mp3",
      duration: 1050.576,
    },
    {
      title: "Magically Drawn to The Source",
      src: "/songs/2024-05-12/magically_drawn_to_the_source.mp3",
      duration: 784.656,
    },
  ],
};
export default album;
