import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Stealing Candy from Babies",
  date: new Date(2024, 5, 30),
  img: "/img/explosion.jpeg",
  video: "/videos/explosion.mp4",
  tracks: [
    {
      title: "Osmium Dreams",
      src: "/songs/2024-06-30/osmium_dreams.mp3",
      duration: 937.32,
    },
    {
      title: "One Small Step For A Man",
      src: "/songs/2024-06-30/one_small_step_for_a_man.mp3",
      duration: 648.24,
    },
    {
      title: "For One Timeless Moment",
      src: "/songs/2024-06-30/for_one_timeless_moment.mp3",
      duration: 951.144,
    },
    {
      title: "The Horse You Rode In On",
      src: "/songs/2024-06-30/the_horse_you_rode_in_on.mp3",
      duration: 1013.736,
    },
    {
      title: "International Assassins",
      src: "/songs/2024-06-30/international_assassins.mp3",
      duration: 807.984,
    },
    {
      title: "Maserati, Meet Open Roads",
      src: "/songs/2024-06-30/maserati_meet_open_roads.mp3",
      duration: 570.264,
    },
    {
      title: "Lush Oasis",
      src: "/songs/2024-06-30/lush_oasis.mp3",
      duration: 477.24,
    },
    {
      title: "Moonwalking On The Darkside",
      src: "/songs/2024-06-30/moonwalking_on_the_darkside.mp3",
      duration: 712.368,
    },
    {
      title: "Two For The Price Of Fun",
      src: "/songs/2024-06-30/two_for_the_price_of_fun.mp3",
      duration: 1025.856,
    },
    {
      title: "The Three Minute Rule",
      src: "/songs/2024-06-30/the_three_minute_rule.mp3",
      duration: 179.736,
    },
    {
      title: "Flare For The Dramatic",
      src: "/songs/2024-06-30/flare_for_the_dramatic.mp3",
      duration: 916.992,
    },
    {
      title: "Open Air Market",
      src: "/songs/2024-06-30/open_air_market.mp3",
      duration: 889.344,
    },
    {
      title: "Happy Nomads",
      src: "/songs/2024-06-30/happy_nomads.mp3",
      duration: 1041.696,
    },
    {
      title: "A Fitting End To A Lovely Evening",
      src: "/songs/2024-06-30/a_fitting_end.mp3",
      duration: 1256.736,
    },
  ],
};
export default album;
