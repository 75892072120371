import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "The Tree of Life",
  date: new Date(2024, 4, 26),
  img: "/img/tree-of-life.jpeg",
  video: "/videos/tree-of-life.mp4",
  tracks: [
    {
      title: "Ode To Covert Cafe",
      src: "/songs/2024-05-26/ode_to_covert_cafe.mp3",
      duration: 625.728,
    },
    {
      title: "Robust Blend",
      src: "/songs/2024-05-26/robust_blend.mp3",
      duration: 1155.72,
    },
    {
      title: "The Question",
      src: "/songs/2024-05-26/the_question.mp3",
      duration: 966.792,
    },
    {
      title: "If You Incest",
      src: "/songs/2024-05-26/if_you_incest.mp3",
      duration: 896.952,
    },
    {
      title: "Slow Burn",
      src: "/songs/2024-05-26/slow_burn.mp3",
      duration: 1230.336,
    },
    {
      title: "Frozen Yogurt",
      src: "/songs/2024-05-26/frozen_yogurt.mp3",
      duration: 701.736,
    },
    {
      title: "Rocky Mountain Man",
      src: "/songs/2024-05-26/rocky_mountain_man.mp3",
      duration: 885.48,
    },
    {
      title: "All Inclusive",
      src: "/songs/2024-05-26/all_inclusive.mp3",
      duration: 1354.224,
    },
    {
      title: "New Gadgets, Old Friends",
      src: "/songs/2024-05-26/new_gadgets_old_friends.mp3",
      duration: 693.048,
    },
    {
      title: "Every Time You Look My Way",
      src: "/songs/2024-05-26/every_time_you_look_my_way.mp3",
      duration: 807.696,
    },
    {
      title: "(I Will Be A) Good Listener",
      src: "/songs/2024-05-26/good_listener.mp3",
      duration: 1026.768,
    },
    {
      title: "You're The Lucky One",
      src: "/songs/2024-05-26/places_youve_never_been_before.mp3",
      duration: 614.76,
    },
    {
      title: "Smooth Operators",
      src: "/songs/2024-05-26/smooth_operators.mp3",
      duration: 650.952,
    },
  ],
};
export default album;
