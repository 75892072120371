import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "A Night of A Thousand Stars",
  date: new Date(2024, 5, 9),
  img: "/img/starlight.jpeg",
  video: "/videos/starlight.mp4",
  tracks: [
    {
      title: "You Just Can't Forget",
      src: "/songs/2024-06-09/you_just_cant_forget.mp3",
      duration: 801.048,
    },
    {
      title: "The Amethyst Prism Of The Netherzones",
      src: "/songs/2024-06-09/amethyst_prism.mp3",
      duration: 1289.976,
    },
    {
      title: "Let's Play With Knives",
      src: "/songs/2024-06-09/lets_play_with_knives.mp3",
      duration: 835.392,
    },
    {
      title: "Turkey Beef Jerky",
      src: "/songs/2024-06-09/turkey_beef_jerky.mp3",
      duration: 821.496,
    },
    {
      title: "Epic Forty Minute Jam",
      src: "/songs/2024-06-09/epic_forty_minute_jam.mp3",
      duration: 2395.536,
    },
    {
      title: "Earth Chakras",
      src: "/songs/2024-06-09/earth_chakras.mp3",
      duration: 851.592,
    },
    {
      title: "Walkin' Through The Park",
      src: "/songs/2024-06-09/walkin_through_the_park.mp3",
      duration: 687.048,
    },
    {
      title: "Hard Letting Go",
      src: "/songs/2024-06-09/hard_letting_go.mp3",
      duration: 376.848,
    },
    {
      title: "Rodger That: Synth Edition",
      src: "/songs/2024-06-09/rodger_that_synth_edition.mp3",
      duration: 240.288,
    },
    {
      title: "two_not_covers",
      src: "/songs/2024-06-09/two_not_covers.mp3",
      duration: 474.576,
    },
  ],
};
export default album;
