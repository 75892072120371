import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Red Light District",
  date: new Date(2023, 9, 8),
  img: "/img/walking-home.jpeg",
  video: "/videos/walking-home.mp4",
  tracks: [
    {
      title: "Deftly Wielded Handaxe",
      src: "/songs/2023-10-08/deftly_wielded_handaxe.mp3",
    },
    {
      title: "A Sordid Affair",
      src: "/songs/2023-10-08/a_sordid_affair.mp3",
    },
    {
      title: "Take Me To Your Heart",
      src: "/songs/2023-10-08/take_me_to_your_heart.mp3",
    },
    {
      title: "Yesterday Was My Day",
      src: "/songs/2023-10-08/yesterday_was_my_day.mp3",
    },
    {
      title: "Rescue My Beer",
      src: "/songs/2023-10-08/rescue_my_beer.mp3",
    },
    {
      title: "Pickleball Alley",
      src: "/songs/2023-10-08/pickleball_alley.mp3",
    },
    {
      title: "(I Just Need) One More Night",
      src: "/songs/2023-10-08/one_more_night.mp3",
    },
    {
      title: "Subliminal Messages",
      src: "/songs/2023-10-08/subliminal_messages.mp3",
    },
    {
      title: "Lie Of Omission",
      src: "/songs/2023-10-08/lie_of_omission.mp3",
    },
    {
      title: "I've been Lonely For Nights (And Maybe For Years)",
      src: "/songs/2023-10-08/ive_been_lonely_for_nights.mp3",
    },
    {
      title: "Happy Halloween Turnaround",
      src: "/songs/2023-10-08/happy_halloween_turnaround.mp3",
    },
  ],
};
export default album;
