import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Addictive Additions",
  date: new Date(2024, 5, 16),
  img: "/img/pied-piper.jpeg",
  video: "/videos/pied-piper.mp4",
  tracks: [
    {
      title: "Exploring The Consequences",
      src: "/songs/2024_06_16/exploring_the_consequences.mp3",
      duration: 591.912,
    },
    {
      title: "Circle of Ravens",
      src: "/songs/2024_06_16/circle_of_ravens.mp3",
      duration: 528.144,
    },
    {
      title: "Time to Pay The Piper",
      src: "/songs/2024_06_16/time_to_pay_the_piper.mp3",
      duration: 926.448,
    },
    {
      title: "A Way to Fly",
      src: "/songs/2024_06_16/a_way_to_fly.mp3",
      duration: 561.936,
    },
    {
      title: "Intensity of Smiles",
      src: "/songs/2024_06_16/intensity_of_smiles.mp3",
      duration: 120.552,
    },
    {
      title: "Say It Fast",
      src: "/songs/2024_06_16/say_it_fast.mp3",
      duration: 584.64,
    },
    {
      title: "There's Beauty in Chaos",
      src: "/songs/2024_06_16/theres_beauty_in_chaos.mp3",
      duration: 881.472,
    },
    {
      title: "Welcome to Jazz Club. Nice",
      src: "/songs/2024_06_16/welcome_to_jazz_club_nice.mp3",
      duration: 856.176,
    },
    {
      title: "Furtive Toil",
      src: "/songs/2024_06_16/furtive_toil.mp3",
      duration: 657.792,
    },
    {
      title: "Until the Rains Come",
      src: "/songs/2024_06_16/until_the_rains_come.mp3",
      duration: 786.312,
    },
    {
      title: "Spanish Bassoon",
      src: "/songs/2024_06_16/spanish_bassoon.mp3",
      duration: 442.848,
    },
    {
      title: "It's Art Jim (but not as we know it)",
      src: "/songs/2024_06_16/it_art_jim.mp3",
      duration: 1298.16,
    },
  ],
};
export default album;
