import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Intended Consequences",
  date: new Date(2024, 4, 19),
  img: "/img/devil-paella.jpeg",
  video: "/videos/devil-paella.mp4",
  tracks: [
    {
      title: "Paella Playa",
      src: "/songs/2024-05-19/paella_playa.mp3",
      duration: 1278.36,
    },
    {
      title: "Walkin' Tall",
      src: "/songs/2024-05-19/walkin_tall.mp3",
      duration: 773.352,
    },
    {
      title: "Silly Little Thoughts",
      src: "/songs/2024-05-19/silly_little_thoughts.mp3",
      duration: 1268.256,
    },
    {
      title: "What Am Me?",
      src: "/songs/2024-05-19/what_am_me.mp3",
      duration: 987.936,
    },
    {
      title: "Blasphemies",
      src: "/songs/2024-05-19/blasphemies.mp3",
      duration: 2259.888,
    },
    {
      title: "It's The Unforgivable",
      src: "/songs/2024-05-19/its_the_unforgivable.mp3",
      duration: 1196.952,
    },
    {
      title: "Building Bridges",
      src: "/songs/2024-05-19/building_bridges.mp3",
      duration: 886.392,
    },
    {
      title: "(I'm Gonna) Give It Some",
      src: "/songs/2024-05-19/give_it_some.mp3",
      duration: 592.728,
    },
    {
      title: "I'd Rather Be Here",
      src: "/songs/2024-05-19/id_rather_be_here.mp3",
      duration: 493.776,
    },
  ],
};
export default album;
