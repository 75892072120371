import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Not that kind of church..",
  date: new Date(2023, 2, 26),
  img: "/img/men-smoking.jpeg",
  video: "/videos/men-smoking.mp4",
  tracks: [
    {
      title: "Leisurely",
      src: "/songs/2023-03-26/leisurely.mp3",
    },
    {
      title: "Tiny Little Cable pt 1",
      src: "/songs/2023-03-26/tiny_little_cable_part_one.mp3",
    },
    {
      title: "Triumph",
      src: "/songs/2023-03-26/triumph.mp3",
    },
    {
      title: "Tiny Little Cable pt 2",
      src: "/songs/2023-03-26/tiny_little_cable_part_two.mp3",
    },
    {
      title: "Blind as can be",
      src: "/songs/2023-03-26/blind_as_can_be.mp3",
    },
    {
      title: "Slow & Low",
      src: "/songs/2023-03-26/slow_and_low.mp3",
    },
    {
      title: "Lost & Found",
      src: "/songs/2023-03-26/lost_and_found.mp3",
    },
    {
      title: "Chemistry Class",
      src: "/songs/2023-03-26/chemistry_class.mp3",
    },
    {
      title: "Swingers Club",
      src: "/songs/2023-03-26/swingers_club.mp3",
    },
    {
      title: "Flap Jack",
      src: "/songs/2023-03-26/flap_jack.mp3",
    },
  ],
};
export default album;
